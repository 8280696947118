import $ from 'jquery';


/**
 * Control to hide and show notices depending on data key
 */
export class Notice {

    constructor() {
        this.noticeSection = $('.pmc-notice');
        const hiddenSelector = '.pmc-notice--hidden';
        $(hiddenSelector, this.noticeSection).each((ind, elm)=> {
           const noticeKey = $(elm).attr('data-key');
           if(!!noticeKey) {
               $(elm).attr('aria-hidden', !!localStorage.getItem(noticeKey));
           }
        });
        this.noticeSection.on('click','.close', (e) => {
            const noticeBox = $(e.target).closest(hiddenSelector);
            const noticeKey = noticeBox?.attr('data-key');
            if(!!noticeKey) {
                noticeBox.attr('aria-hidden', true);
                localStorage.setItem(noticeKey, 'dismiss');
            }
        });
    }



}