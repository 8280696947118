
/**
 * simple implementation throttle function
 * @param callback
 * @param limit
 * @returns {(function(): void)|*}
 */
function throttle (callback, wait= 300) {
    let waiting = false;
    return function () {
        if (waiting) {
            return;
        }
        waiting = true;
        setTimeout(function () {
            callback.apply(this, arguments);
            waiting = false;
        }, wait);
    }
}

export class BackToTop {
    constructor(backToTopButton, offset) {
        this.windowScrollElement = $('html, body');
        this.backToTopButton = backToTopButton;
        this.windowElement = $(window);
        this.offset = offset || 0;
        this.initBackToTopButton();
        this.initScroll();
    }
    /**
    * Initialize Back to Top button:
    *
    * - click on Back to Top button = scroll to the top
    */
    initBackToTopButton() {
        this.backToTopButton.on('click', (e) => {
          e.preventDefault();
          this.windowScrollElement
            .stop(true, false)
            .animate({scrollTop: 0}, 300);
        });
    }

    /**
     * Initialize Back To Top button visibility on 'scroll' event
     *
     * PMC-47660
     * adjust the behavior so the button appears only after scrolling up in the browser
     */
    initScroll() {
        let lastScrollTop = 0;
        this.windowElement.scroll(throttle(()=>{
            const scrollTop = this.windowElement.scrollTop();
            if (scrollTop > this.offset && scrollTop < lastScrollTop) {
                this.backToTopButton.addClass('visible');
            } else {
                this.backToTopButton.removeClass('visible');
            }
            lastScrollTop =  scrollTop;
        }, 100));
    }
}