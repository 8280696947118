import NCBIAutocomplete from 'ncbi-autocomplete';
import $ from 'jquery';
import 'corejs-typeahead/dist/typeahead.jquery';


/**
 * Control to manage a search on  a page
 */
export class Search {
    constructor() {
        this.searchForm = $('div[role=search]');
        this.searchField = this.searchForm.find('#pmc-search');

        const autocompleteUrl = this.searchField.attr('autocomplete-url') || '';
        this.autocomplete = new NCBIAutocomplete({
            target: this.searchField,
            apiUrl: autocompleteUrl,
            options: {
              limit:20,
            },
        });
       this.autocomplete.typeahead.bind('typeahead:render', function() {
           const menu = $('.tt-menu');
           const suggestionsCount = $('.tt-suggestion', menu).length;
           if(!$('.tt-footer', menu).length){
               menu.append("<div class='tt-footer'>Scroll to see more ...</div>");
           }
           $('.tt-footer', menu).attr('aria-hidden', suggestionsCount <= 5);

       });

        this.initClearBtn();
    }

    initClearBtn() {
        const clearBtn = this.searchForm.find('.clear-btn');
        this.searchField.on("input", function(){
            clearBtn.toggle(!!this.value);
        });
        clearBtn.on("touchstart click", e => {
            e.preventDefault();
            this.searchField.val("").trigger("input");
        });
    }
}
