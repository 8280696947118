import './base.scss'
import { Search } from '/components/search/search';
import { BackToTop } from '/components/back-to-top/back-to-top';
import { Notice } from "/components/notice/notice";

window.ncbi = window.ncbi || {};
window.ncbi.pmc = window.ncbi.pmc || {};

window.ncbi.pmc.baseSection = {
  init(){
    this.backToTop = new BackToTop($('.back-to-top'), 20);
    this.search = new Search();
    this.notice = new Notice();
    // move notice boxes to top
    $(document).ready(() => {
      $("section[data-section=Alerts]").insertAfter('.usa-banner');
      $('.pmc-notice').insertAfter('.usa-banner');
    });
  },
};

window.ncbi.pmc.baseSection.init();